export const dk = [
  "og",
  "i",
  "jeg",
  "det",
  "at",
  "en",
  "den",
  "til",
  "er",
  "som",
  "på",
  "de",
  "med",
  "han",
  "af",
  "for",
  "ikke",
  "der",
  "var",
  "mig",
  "sig",
  "men",
  "et",
  "har",
  "om",
  "vi",
  "min",
  "havde",
  "ham",
  "hun",
  "nu",
  "over",
  "da",
  "fra",
  "du",
  "ud",
  "sin",
  "dem",
  "os",
  "op",
  "man",
  "hans",
  "hvor",
  "eller",
  "hvad",
  "skal",
  "selv",
  "her",
  "alle",
  "vil",
  "blev",
  "kunne",
  "ind",
  "når",
  "være",
  "dog",
  "noget",
  "ville",
  "jo",
  "deres",
  "efter",
  "ned",
  "skulle",
  "denne",
  "end",
  "dette",
  "mit",
  "også",
  "under",
  "have",
  "dig",
  "anden",
  "hende",
  "mine",
  "alt",
  "meget",
  "sit",
  "sine",
  "vor",
  "mod",
  "disse",
  "hvis",
  "din",
  "nogle",
  "hos",
  "blive",
  "mange",
  "ad",
  "bliver",
  "hendes",
  "været",
  "thi",
  "jer",
  "sådan",
];
