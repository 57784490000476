export const se = [
  "och",
  "det",
  "att",
  "i",
  "en",
  "jag",
  "hon",
  "som",
  "han",
  "på",
  "den",
  "med",
  "var",
  "sig",
  "för",
  "så",
  "till",
  "är",
  "men",
  "ett",
  "om",
  "hade",
  "de",
  "av",
  "icke",
  "mig",
  "du",
  "henne",
  "då",
  "sin",
  "nu",
  "har",
  "inte",
  "hans",
  "honom",
  "skulle",
  "hennes",
  "där",
  "min",
  "man",
  "ej",
  "vid",
  "kunde",
  "något",
  "från",
  "ut",
  "när",
  "efter",
  "upp",
  "vi",
  "dem",
  "vara",
  "vad",
  "över",
  "än",
  "dig",
  "kan",
  "sina",
  "här",
  "ha",
  "mot",
  "alla",
  "under",
  "någon",
  "eller",
  "allt",
  "mycket",
  "sedan",
  "ju",
  "denna",
  "själv",
  "detta",
  "åt",
  "utan",
  "varit",
  "hur",
  "ingen",
  "mitt",
  "ni",
  "bli",
  "blev",
  "oss",
  "din",
  "dessa",
  "några",
  "deras",
  "blir",
  "mina",
  "samma",
  "vilken",
  "er",
  "sådan",
  "vår",
  "blivit",
  "dess",
  "inom",
  "mellan",
  "sådant",
  "varför",
  "varje",
  "vilka",
  "ditt",
  "vem",
  "vilket",
  "sitta",
  "sådana",
  "vart",
  "dina",
  "vars",
  "vårt",
  "våra",
  "ert",
  "era",
  "vilkas",
];
